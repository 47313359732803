<template>
  <section class="m-merchant-application m-scroll">
    <!-- 头部导航 -->
    <m-header :title="title"></m-header>
    <van-tabs v-model="tab" animated title-active-color="#ED2A24" line-width="32px" line-height="2px" :border="false" sticky
    swipeable :offset-top="top" v-if="status == -1">
        <van-tab title="企业商户入驻">
          <div class="form">
            <p class="label">企业名称</p>
            <input v-model="form.corporate_name"  type="text" class="reset-input" placeholder="请输入">
            <p class="label">经营类目</p>
            <div class="input-select" @click="itemPicker = true">
              <input v-model="form.category" type="text" readonly placeholder="请选择经营类目">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <!-- <input v-model="form.item" type="text" class="bottom-input" placeholder="请输入您的经营类目"> -->
          </div>

          <div class="form">

            <p class="label">企业邮箱<span v-if="emailTrue">请输入正确的邮箱</span></p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="请输入">

            <p class="label">联系人</p>
            <div class="input-box">
              <input v-model="form.surname" type="text" class="reset-input" placeholder="名称">
              <input v-model="form.name"  type="text" class="reset-input" placeholder="姓氏">
            </div>

            <!-- <p class="label">联系人职务</p>
            <input v-model="form.position" type="text" class="reset-input" placeholder="请输入"> -->

            <p class="label">联系电话<span v-if="phoneTrue">请输入正确的联系电话</span></p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.area_code == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.area_code}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>
          </div>

          <div class="form">

            <p class="label">合同邮寄地址</p>
            <div class="input-select" style="margin-bottom:10px" @click="showPicker = true">
              <input v-model="cityText" type="text" readonly placeholder="选择国家-省份-城市">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.addr" type="text" class="reset-input" placeholder="请输入合同邮寄详细地址并正确填写邮编">

            <p class="label" style="margin-bottom:10px">企业营业执照</p>
            <m-upload :img.sync="form.license_pic" file-name="MerchantApply" text="营业执照"></m-upload>
          </div>

          <div class="footer">
            <van-button @click="NextBussiness" :disabled="disabled" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提交审核</van-button>
            <van-button  :disabled="disabled" round block type="info" native-type="submit" color="#3284FA" plain style="margin-top:16px"
            @click="viewContract">查看合同</van-button>
          </div>

        </van-tab>


        <van-tab title="个人商户入驻" :disabled="isUpdate">

          <div class="form">
            <p class="label">店铺名称</p>
            <input v-model="form.corporate_name"  type="text" class="reset-input" placeholder="请输入">

            <p class="label">经营类目</p>
            <div class="input-select" @click="itemPicker = true">
              <input v-model="form.category" type="text" readonly placeholder="请选择经营类目">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.item" type="text" class="bottom-input" placeholder="请输入您的经营类目">
          </div>

          <div class="form">

            <p class="label">联系邮箱<span v-if="emailTrue">请输入正确的邮箱</span></p>
            <input v-model="form.email" type="text" class="reset-input" placeholder="请输入">

            <p class="label">联系人</p>
            <div class="input-box">
              <input v-model="form.surname" type="text" class="reset-input" placeholder="名称">
              <input v-model="form.name"  type="text" class="reset-input" placeholder="姓氏">
            </div>

            <p class="label">联系电话<span v-if="phoneTrue">请输入正确的联系电话</span></p>
            <div class="input-phone">
              <div @click="telPicker = true">
                <img src="@/assets/public/icon_jiananda@2x.png" alt="canada" v-if="form.area_code == '1'">
                <img src="@/assets/public/china.png" alt="canada" v-else>
                <span>+{{form.area_code}}</span>
              </div>
              <input v-model="form.phone" type="text" >
            </div>
          </div>

          <div class="form">

            <p class="label">合同邮寄地址</p>
            <div class="input-select" style="margin-bottom:10px" @click="showPicker = true">
              <input v-model="cityText" type="text" readonly placeholder="选择国家-省份-城市">
              <img src="@/assets/merchant/icon-xiala.png" alt="">
            </div>
            <input v-model="form.addr" type="text" class="reset-input" placeholder="请输入合同邮寄详细地址并正确填写邮编">

            <p class="label" style="margin-bottom:10px">联系人驾驶证/护照</p>
            <div class="card-upload">
              <m-upload :img.sync="form.id_card_pic_one" file-name="MerchantApply" text="正面照片"></m-upload>
              <m-upload :img.sync="form.id_card_pic_two" file-name="MerchantApply" text="反面照片"></m-upload>
            </div>
            
          </div>

          <div class="footer">
            <van-button @click="NextPreson" :disabled="disabled" round block type="info" native-type="submit" color="linear-gradient(315deg,rgba(240,60,24,1) 0%,rgba(240,100,72,1) 100%)">提交审核</van-button>
            <van-button  :disabled="disabled" round block type="info" native-type="submit" color="#3284FA" plain style="margin-top:16px"
            @click="viewContract">
              查看合同
            </van-button>
          </div>

        </van-tab>
    </van-tabs>
    <van-action-sheet v-model="telPicker" :actions="actions" cancel-text="取消" close-on-click-action @cancel="telPicker = false" @select="handlearea_code" />

    <!-- 选择经营类目 -->
    <van-popup v-model="itemPicker" position="bottom">
      <van-picker show-toolbar title="选择经营类目" :columns="items" @confirm="handleItem" value-key="title" @cancel="cancelItem" />
    </van-popup>

    <!-- 选择地址 -->
    <van-popup v-model="showPicker" position="bottom">
      <van-picker show-toolbar title="选择城市" :columns="columns" @confirm="handleCity" value-key="ename" @cancel="showPicker = false" />
    </van-popup>
    

    <!-- 审核状态 -->
    <div class="merchant-status" v-if="status == 0">
      <img src="@/assets/merchant/empty.png" alt="">
      <h5>正在审核中...</h5>
      <p>您的申请正在审核中，请您耐心等待</p>
      <div class="empty-button" @click="$router.push('/zh/customer')">联系客服</div>
    </div>
    <div class="merchant-status" v-if="status == 1">
      <img src="@/assets/payment/success@2x.png" alt="" class="status-img">
      <h5>恭喜你，审核通过！</h5>
      <p>您的入驻申请已审核成功，请前往PC端商家后台</p>
      <p style="color:#000">https://mystoe.moobyyoho.com</p>
      <p>登录账号为您申请时所填手机号码</p>
      <p style="color:#F06448">*首次登录请用短信验证码登录</p>
      <div class="color-button" @click="$router.push(`/zh/merchant/index/${shop_id}`)">我的店铺</div>
      <div class="empty-button" @click="updateCompany" v-if="shop_type == 0">升级位企业商户</div>
    </div>
    <div class="merchant-status" v-if="status == 2">
      <img src="@/assets/payment/fail.png" alt="" class="status-img">
      <h5>审核失败</h5>
      <p>您的入驻申请暂未通过审核，Mooby客服将在7个工作日内与您联系，请您注意查看邮箱信息</p>
      <div class="color-button" style="margin-top:50px" @click="reApplyHandle">重新申请</div>
    </div>
  </section>
</template>

<script>
import MHeader  from '@/components/zh/m-header.vue'
import { category, city, shop_apply, reApply } from '@/api/zh/mine.js'
import MUpload from '@/components/zh/m-upload.vue'

export default {
  name:'MerchantApply',
  components:{ MHeader, MUpload },
  data(){
    return {
      tab:0,          // tabs选中的面板
      top:44,
      form:{
        corporate_name:'',
        email:'',
        surname:'',
        name:'',
        position: '',
        area_code:1,
        phone:'',
        item:'',
        addr: '',
        license_pic: {url: '', pic_id: '' },
        id_card_pic_one: {url: '', pic_id: '' },
        id_card_pic_two: {url: '', pic_id: '' },
      },
      cid: '',
      city:[],
      cityText: '',

      actions: [{ name: '1' }, { name: '86' },],
      telPicker:false,
      itemPicker:false,
      items:[ ],
      showPicker: false,
      columns: [],

      agree:false,
      disabled: false,
      emailTrue: false,
      phoneTrue: false,
      title: '商户入驻申请',
      status: -1,
      isUpdate: false,
      shop_type: 0,
      shop_id: 1
    }
  },
  created() {
    this.getCategory()
    this.getCity()

    if(this.$route.query.s) {
      this.status = this.$route.query.s
      if(this.status == 1) {
        this.shop_type = this.$route.query.shop_type
        this.shop_id = this.$route.query.shop_id
      }
    }

    // 判断状态
    if(this.status == -1) {
      this.title = '商户入驻申请'
    } else {
      this.title = '审核'
    }
  },

  mounted(){
    this.tab = 0
    const clientWidth = document.body.clientWidth
    this.top = 44/375*clientWidth
  },

  methods:{
    viewContract() {
      // 查看合同
      this.download('https://m.moobyyoho.com/contract/company.pdf', 'contract.pdf')
    },
    download(url, name) {
        // 发送http请求，将文件链接转换成文件流
        let that = this
        this.fileAjax(url, function(xhr) {
            that.downloadFile(xhr.response, name)
        }, {
            responseType: 'blob'
        })
    },
    fileAjax(url, callback, options) {
        let xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        if (options.responseType) {
            xhr.responseType = options.responseType
        }
        xhr.onreadystatechange = function() {
            if (xhr.readyState === 4 && xhr.status === 200) {
                callback(xhr)
            }
        }
        xhr.send()
    },
    downloadFile(content, filename) {
        window.URL = window.URL || window.webkitURL
        let a = document.createElement('a')
        let blob = new Blob([content])
        // 通过二进制文件创建url
        let url = window.URL.createObjectURL(blob)
        a.href = url
        a.download = filename
        a.click()
        // 销毁创建的url
        window.URL.revokeObjectURL(url)
    },
    // 经营类目
    getCategory() {
      category().then(res => {
        if(res) {
          this.items = res.data
          this.items.forEach(e=> {
            delete e.children
          })
        }
      })
    },

    // 获取城市列表
    getCity() {
      city().then(res => {
        if(res) {
          this.columns = res.data
          this.columns.forEach(e => {
            e.children.forEach(el => {
              el.children.forEach(ele => {
                delete ele.children
              })
            })
          })
        }
      })
    },

    // 选择区号
    handlearea_code(it){
      this.form.area_code = it.name
      this.telPicker = false
    },
    // 上传正面照片
    handleUpload(file){
      this.imgId = 1
      this.imgSrc = file.content
    },
    // 选择经营类目
    handleItem(value){
      this.form.category = value.title
      this.cid = value.id
      this.itemPicker = false
    },

    // 取消 经营类目
    cancelItem() {
      this.form.category = ''
      this.cid           = ''
      this.itemPicker    = false
    },

    // 选择城市 确认
    handleCity(value, ids){
      this.cityText = value.join('-')
      this.city = []
      if(ids.length == 3) {
        this.city.push(this.columns[ids[0]].id)
        this.city.push(this.columns[ids[0]].children[ids[1]].id)
        this.city.push(this.columns[ids[0]].children[ids[1]].children[ids[2]].id)
      }
      this.showPicker = false
    },




    // 提交
    NextBussiness(){

      if(this.form.corporate_name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善公司名称' })
        return false
      }
      if(this.cid == '' && this.form.category == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善经营类目' })
        return false
      }
      if(this.form.email == '' || this.isTrueEmail(this.form.email)) {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善企业邮箱' })
        return false
      }
      if(this.form.surname == '' || this.form.name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善联系人姓名' })
        return false
      }
      // if(this.form.position == '') {
      //   this.$notify({ type: 'warning', duration: 1500, message: '请完善联系人职务' })
      //   return false
      // }
      if(this.form.phone == '' || this.isTruePhone(this.form.phone)) {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善联系电话' })
        return false
      }
      if(this.form.cityText == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善合同邮寄地址' })
        return false
      }
      if(this.form.addr == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善详细地址&邮编' })
        return false
      }
      if(this.form.license_pic.pic_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请上传公司营业执照' })
        return false
      }
      this.disabled = true
      let params = {
        shops_type     : 1,
        corporate_name : this.form.corporate_name,
        cid            : this.cid,
        custom_category: this.form.item,
        email          : this.form.email,
        surname        : this.form.surname,
        name           : this.form.name,
        area_code      : this.form.area_code,
        phone          : this.form.phone,
        country        : this.city[0],
        province       : this.city[1],
        city           : this.city[2],
        addr           : this.form.addr,
        license_pic    : this.form.license_pic.pic_id
      }

      shop_apply(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: res.msg })
          this.$router.go(-1)
        }
      }).finally( () => {
        this.disabled = false
      })
    },


    // 个人商户入驻提交
    NextPreson(){

      if(this.form.corporate_name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善店铺名称' })
        return false
      }
      if(this.cid == '' && this.form.category == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善经营类目' })
        return false
      }
      if(this.form.email == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善联系邮箱' })
        return false
      }
      if(this.form.surname == '' || this.form.name == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善联系人姓名' })
        return false
      }
      if(this.form.phone == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善联系电话' })
        return false
      }
      if(this.form.cityText == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善合同邮寄地址' })
        return false
      }
      if(this.form.addr == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请完善详细地址&邮编' })
        return false
      }
      if(this.form.id_card_pic_one.pic_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请上传身份证(签证)正面' })
        return false
      }
      if(this.form.id_card_pic_two.pic_id == '') {
        this.$notify({ type: 'warning', duration: 1500, message: '请上传身份证(签证)反面' })
        return false
      }
      this.disabled = true
      let params = {
        shops_type     : 0,
        corporate_name : this.form.corporate_name,
        cid            : this.cid,
        custom_category: this.form.item,
        email          : this.form.email,
        surname        : this.form.surname,
        name           : this.form.name,
        area_code      : this.form.area_code,
        phone          : this.form.phone,
        country        : this.city[0],
        province       : this.city[1],
        city           : this.city[2],
        addr           : this.form.addr,
        id_card_pic_one: this.form.id_card_pic_one.pic_id,
        id_card_pic_two: this.form.id_card_pic_two.pic_id,
      }

      shop_apply(params).then(res => {
        if(res) {
          this.$notify({ type: 'success', duration: 2000, message: res.msg })
          this.$router.go(-1)
        }
      }).finally( () => {
        this.disabled = false
      })
    },
    isTrueEmail(v) {
      /* eslint-disable */
      let patt = /^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$/
      return !patt.test(v)
    },
    isTruePhone(v) {
      return v.length < 10
    },
    reApplyHandle() {
      // 重新申请
      reApply().then(res => {
        if(res) {
          this.status = -1
        } else {
          this.$notify({ type: 'warning', duration: 2000, message: res.msg })
        }
      })
    },
    updateCompany() {
      // 升级为企业
      this.tab = 0
      this.isUpdate = true
    }
  },
  watch: {
    'form.email'(val) {
      if(this.isTrueEmail(val)) {
        this.emailTrue = true
      } else {
        this.emailTrue = false
      }
    },
    'form.phone'(val) {
      if(this.isTruePhone(val)) {
        this.phoneTrue = true
      } else {
        this.phoneTrue = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './application.less';

.merchant-status{
  img{width:200px;margin:0 auto;display: block;margin-top:80px}
  .status-img{width:120px}
  h5{font-size: 16px;text-align: center;letter-spacing: 1.125px;color: #000000;line-height: 40px;margin-top:20px}
  p{font-size: 14px;color:#888;text-align: center;padding:0 30px;line-height: 21px;}
  .empty-button,.color-button{width:300px;height:42px;border-radius: 42px;border:1px solid #2562B9;font-size: 16px;color:#2562B9;line-height: 42px;
  text-align: center;background-color: #fff;margin:20px auto 0 auto}
  .color-button{border:none;background:linear-gradient(295.9deg, #F03C18 12.2%, #F06448 82.1%);color:#fff}
}
</style>